



































































































import Vue from "vue";
import Component from "vue-class-component";
import BaseApp from "@/BaseApp.vue";
import Error from "@/shared/notifications/Error.vue";
import Btn from "@/shared/Btn.vue";

import { C } from "./controller";
import { ValidEvent } from "@/shared/utils";

@Component({
  components: { BaseApp, Error, Btn }
})
export default class App extends Vue {
  title = "Portail ACVE";
  mailLien = "mailto:contact@acve.asso.fr?subject=[Lien Espace Personnel]";

  mail = "";

  showDialog = false;
  mailFound = false;

  C = C;
  output = C.notifications;

  async searchMail(event: ValidEvent) {
    event.spinning = true;
    const rep = await C.searchMail(this.mail);
    event.spinning = false;
    if (rep == undefined) return;
    this.mailFound = rep.Found;
    this.showDialog = true;
  }
}
