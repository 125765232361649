import {
  BaseController,
  adjustUrlOrigin,
  UrlServerBase
} from "@/shared/logic/controller";
import { SearchMailOut, SearchMailIn } from "@/shared/logic/types";
import Axios, { AxiosResponse } from "axios";
import { Misc } from "@/shared/logic/api";

export class Controller extends BaseController {
  async searchMail(mail: string) {
    const params: SearchMailIn = { Mail: mail };
    try {
      let resp: AxiosResponse<SearchMailOut> = await Axios.post(
        UrlServerBase + Misc.SearchMail,
        params
      );
      return resp.data;
    } catch (error) {
      this.notifications.onAxiosError(error);
    }
  }
}

export const C = new Controller();
